/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

@media (min-width: 768px) {
  .HolyGrailBody {
    flex-direction: row;
    flex: 1; }
  .HolyGrailContent {
    flex: 1; }
  .HolyGrailNav,
  .HolyGrailAds {
    /* 12em is the width of the columns */
    flex: 0 0 12em; } }

.row-Reports .col-lg-9 .Card-info .card-body {
  max-height: 240px; }

.component-History .box-content > div {
  max-height: 550px; }

.component-History header.report-Heading {
  margin-top: 75px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e7eaec; }
  .component-History header.report-Heading h2 {
    font-size: 1.75rem;
    margin-bottom: 10px; }
  .component-History header.report-Heading:first-of-type {
    margin-top: 0; }

.component-History .content {
  margin-bottom: 20px;
  font-size: 15px; }

.component-History main > div > header.report-Heading {
  margin-top: 75px !important; }

.component-History header.report-Heading--main {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .component-History header.report-Heading--main h1 {
    margin: 0; }
  .component-History header.report-Heading--main > div {
    text-align: right; }
    .component-History header.report-Heading--main > div p {
      margin-bottom: 0; }

.component-History .report-SectionHeader {
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: #F3F3F4;
  font-size: 1.25rem;
  padding: 10px; }
  .component-History .report-SectionHeader h2 {
    margin-bottom: 0; }

.report-Item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 5px; }
  .report-Item .report-Item_Icon {
    text-align: center;
    margin-right: 25px;
    min-width: 120px; }
  .report-Item .report-Item_Icon h2 {
    font-size: 17px; }
  .report-Item .report-Item_Icon h2 small {
    display: block;
    margin-top: 5px; }
  .report-Item .report-Item_Content {
    position: relative;
    background: #F3F3F4;
    font-size: 15px;
    border-radius: 0.25em;
    padding: 10px; }
    .report-Item .report-Item_Content:before {
      content: "";
      position: absolute;
      top: 16px;
      right: 100%;
      height: 0;
      width: 0;
      border: 7px solid transparent;
      border-right: 7px solid white;
      border-color: transparent #f5f5f5 transparent transparent; }
    .report-Item .report-Item_Content p:last-child {
      margin-bottom: 0; }
  .report-Item .resource-Profile {
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto 15px;
    overflow: hidden; }
    .report-Item .resource-Profile img {
      width: 100%;
      height: auto;
      display: block; }

.resource-Body {
  width: 120px;
  text-align: center; }

.pdf-Page-Break {
  page-break-after: always;
  clear: both; }

.break-after {
  display: block;
  page-break-after: always;
  position: relative; }

.break-before {
  display: block;
  page-break-before: always;
  position: relative; }

.gantt-zoom {
  transform: scale(0.8); }

.drawerStyle {
  top: "165px"; }

.menuStyle {
  text-align: left;
  font-size: 80%;
  word-wrap: break-word; }

.chartStyle {
  width: 500px;
  height: 500px; }

.tabStyle {
  font-size: 80%;
  padding-top: 30; }

.tabs {
  width: 400px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top; }

.buttonVisible {
  visibility: visible; }

.buttonHidden {
  visibility: hidden; }

.tabs-1 .tab-links {
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-bottom: 1px solid #868686;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 35px;
  position: relative;
  top: 5px; }

.tabs-1 .tab-link {
  padding: 0 15px;
  cursor: pointer;
  border: 1px solid transparent;
  display: inline-block;
  position: relative;
  height: 40px;
  line-height: 41px;
  top: -6px;
  left: -1px; }

.tabs-1 .tab-link-active {
  font-weight: bold;
  border: 1px solid #868686;
  border-bottom: 1px solid white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: white; }

.tabs-1 .content {
  padding: 15px;
  border: 1px solid #868686;
  border-top: 1px solid transparent; }

.HolyGrail,
.HolyGrailBody {
  display: flex;
  flex-direction: column; }

.HolyGrailNav {
  order: -1;
  border-right: 1px solid black;
  margin-right: 10px; }

#tasks-status span {
  color: #5378ad;
  font-weight: 600; }

#delayed-task h3 {
  font-size: 20px;
  color: red; }
  #delayed-task h3 i {
    margin-right: 7px;
    color: red; }

#delayed-task p {
  font-size: 16px; }

#weekly-resource-hours-div .table th {
  text-align: center; }

#weekly-resource-hours-div .fixed-width {
  max-width: 250px; }

#weekly-resource-hours-div .worker-productivity-width {
  min-width: 200px; }
