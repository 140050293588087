/**
* Variables
*/
/**
* Base
*/
body {
  color: #29363d;
  background-color: #f0f3f5;
  line-height: 1.55; }

/**
* General
*/
.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05); }

.card-body,
.card-block {
  overflow-y: scroll; }

.modal-header {
  flex-direction: row-reverse; }

body:not(.sidebar-minimized) .sidebar-nav .nav-link {
  display: flex !important; }
  body:not(.sidebar-minimized) .sidebar-nav .nav-link i.fa {
    position: relative;
    top: 3px; }

.ListGroup-Card {
  margin-bottom: 1rem; }
  .ListGroup-Card .card-header {
    cursor: pointer; }
    .ListGroup-Card .card-header:hover {
      background-color: #5378ad;
      color: white; }
    .ListGroup-Card .card-header.active {
      font-weight: 600; }
  .ListGroup-Card .list-group-item {
    cursor: pointer;
    text-align: left; }

.row-Documentation_Content .tab-content {
  border: 0; }

.row-Documentation_Content .tab-pane {
  padding: 0 !important; }

.row-Documentation_Content header {
  margin-bottom: 25px; }

.row-Documentation_Content header h2 {
  margin-top: 0; }

.row-Documentation_Content h4 {
  font-size: 1.35rem; }

.row-Documentation_Content h4 {
  margin-top: 30px;
  font-size: 1.15rem; }

.row-Documentation_Content footer {
  background-color: #d2d7df;
  margin-top: 20px;
  padding: 5px;
  border-radius: 3px;
  color: white; }
  .row-Documentation_Content footer p {
    margin-bottom: 0; }

.row-Documentation_Content .box-content {
  padding-left: 0;
  padding-right: 0;
  border-top: 0; }

.charter-div {
  font-size: 20px; }
  .charter-div strong {
    color: #9e3237; }
  .charter-div p {
    margin-left: 10px; }
  .charter-div .icons-presentation {
    color: #5b7083;
    margin-right: 10px; }
  .charter-div #sub-title-solution {
    text-decoration: underline;
    color: #9e3237; }
  .charter-div #sub-title-solution-list li {
    font-weight: 700; }
  .charter-div .list-circle li {
    list-style: disc; }
  .charter-div #flow-diagram-img {
    text-align: center;
    margin-top: 40px; }
    .charter-div #flow-diagram-img img {
      width: 530px;
      height: 350px; }

/* OrgChart */
.organisation-chart {
  margin: 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .organisation-chart h2 {
    text-align: center;
    color: #5b7083;
    margin-bottom: 50px; }
  .organisation-chart img {
    height: 80%;
    width: 80%; }
  .organisation-chart #icon-chart {
    margin-right: 20px; }

#WBS {
  padding: 20px; }
  #WBS #wbs-icon {
    color: #9e3237;
    margin-right: 20px; }

#task-div h2 {
  margin: 30px 0 0; }

#task-div .task-headers {
  font-weight: 800;
  color: #9e3237; }

#task-div #icon-title {
  font-size: 25px;
  margin-right: 10px; }

#task-div .icons-tasks {
  margin-right: 10px;
  font-size: 12px;
  color: #5b7083; }

#jobs-description-div {
  text-align: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px; }
  #jobs-description-div #jobs-description-card {
    margin-right: 10px;
    width: "80%"; }
  #jobs-description-div .icons-user {
    margin-right: 10px;
    font-size: 15px;
    color: #5378ad; }
  #jobs-description-div #jobs-description-header {
    height: 100px;
    color: #9e3237;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; }

#wrapper-reference-doc {
  text-align: justify; }

.style-background-components {
  line-height: 30px;
  text-align: justify;
  background-color: rgba(255, 255, 255, 0.51);
  padding: 30px;
  border: 1px solid lightgray;
  margin-bottom: 30px; }
  .style-background-components .icon-generic-h2 {
    margin-right: 10px;
    font-size: 30px;
    color: #5378ad; }
  .style-background-components h2 {
    text-align: center;
    color: #5378ad;
    font-weight: 600;
    margin-bottom: 30px; }
  .style-background-components h4 {
    color: #9e3237; }
